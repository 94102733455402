import { createApp } from 'vue';
import App from './modules/App';
import router from './routes';
import store from './store';
import notifyMixin from '@/mixins/notify';
import pagination from './mixins/pagination';
import Notification from "@kyvg/vue3-notification";
import cors from 'cors';
import axios from 'axios';
import AuthService from './services/auth-service';
// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import "@mdi/font/css/materialdesignicons.css";
import "@fortawesome/fontawesome-free/css/all.css";
import { createBootstrap } from 'bootstrap-vue-next'

// Adicionar um interceptor de requisição
axios.interceptors.request.use(
    config => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user && user.accessToken) {
            config.headers['Authorization'] = `${user.tokenType} ${user.accessToken}`;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

// Adicionar um interceptor de resposta
axios.interceptors.response.use(
    response => {
        return response;
    },
    async error => {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            try {
                const response = await AuthService.refreshToken();
                const user = JSON.parse(localStorage.getItem('user'));
                user.accessToken = response.authorisation.token;
                localStorage.setItem('user', JSON.stringify(user));
                axios.defaults.headers.common['Authorization'] = `${user.tokenType} ${user.accessToken}`;
                originalRequest.headers['Authorization'] = `${user.tokenType} ${user.accessToken}`;
                return axios(originalRequest);
            } catch (err) {
                AuthService.logout();
                return Promise.reject(err);
            }
        }
        return Promise.reject(error);
    }
);

const vuetify = createVuetify({
    components,
    directives,
})

const app = createApp(App);

// Usa o Vue Router
app.use(router);

// Usa Vuex store
app.use(store);

// Usa o plugin de notificação
app.use(Notification);
app.use(cors);

// Registrando mixins globalmente (não é recomendado em Vue 3, use Composition API quando possível)
app.mixin(notifyMixin);
app.mixin(pagination);

app.use(vuetify);
app.use(createBootstrap());

app.mount('#app');
