// Import Modules
const dashboardModule = () => import('@/modules/Dashboard');
const folhasObra = () => import('@/modules/FolhasObra');
const matricula = () => import('@/modules/Matricula');
const compra = () => import('@/modules/Compra');
const peca = () => import('@/modules/Peca');
const funcionario = () => import('@/modules/Funcionario');
// Export
export default [
  // Dashboard
  {
    path: '/admin/dashboard',
    component: dashboardModule,
    // meta: { auth: true },
    name: 'admin.dashboard',
  },
  {
    path: '/admin/matricula/:matricula?',
    component: matricula,
    // meta: { auth: true },
    name: 'admin.matricula',
  },
  {
    path: '/admin/folhasobra/:id?',
    component: folhasObra,
    // meta: { auth: true },
    name: 'admin.folhasobra',
  },
  {
    path: '/admin/compra/:id?',
    component: compra,
    // meta: { auth: true },
    name: 'admin.compra',
  },
  {
    path: '/admin/peca/:id?',
    component: peca,
    // meta: { auth: true },
    name: 'admin.peca',
  },
  {
    path: '/admin/funcionario/:id?',
    component: funcionario,
    // meta: { auth: true },
    name: 'admin.funcionario',
  },


];
