export const navbar= {
    namespaced: true,
    state: {
        isSidebarOpen: true,
    },
    mutations: {
        toggleSidebar(state) {
            state.isSidebarOpen = !state.isSidebarOpen;
        },
    },
    actions: {
        toggleSidebar({ commit }) {
            commit('toggleSidebar');
        },
    },
};