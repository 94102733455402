
import { mapGetters } from 'vuex';

export default {
  name: 'App',

  components: {
  },

  computed: {
    ...mapGetters({

    }),
  },
};
