export const funcionario = {
    namespaced: true, // Isso é necessário para módulos namespaced
    state() {
        return {
            funcionarioAtual: JSON.parse(localStorage.getItem('funcionarioAtual')) || null,
        };
    },
    mutations: {
        setFuncionarioAtual(state, funcionario) {
            state.funcionarioAtual = funcionario;
            localStorage.setItem('funcionarioAtual', JSON.stringify(funcionario));
        },
        clearFuncionarioAtual(state) {
            state.funcionarioAtual = null;
            localStorage.removeItem('funcionarioAtual');
        },
    },
    actions: {
        selecionarFuncionario({ commit }, funcionario) {
            commit('setFuncionarioAtual', funcionario);
        },
        terminarFuncionario({ commit }) {
            commit('clearFuncionarioAtual');
        },
    },
    getters: {
        funcionarioAtual: state => state.funcionarioAtual,
    }
};
