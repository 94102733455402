import { createStore } from "vuex";
import { auth } from "./auth.module";
import { navbar } from './navbar';
import { funcionario } from './funcionario';

const store = createStore({
    modules: {
        auth,
        navbar,
        funcionario
    },
});

export default store;