import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL;

class AuthService {
    login(user) {
        return axios
            .post(`${API_URL}login`, {
                email: user.email,
                password: user.password
            })
            .then(response => {
                if (response.data.accessToken && response.data.accessToken.token) {
                    localStorage.setItem('user', JSON.stringify({
                        user: response.data.user,
                        accessToken: response.data.accessToken.token,
                        tokenType: response.data.accessToken.type
                    }));
                }

                return response.data;
            });
    }

    logout() {
        localStorage.removeItem('user');
    }

    register(user) {
        return axios.post(`${API_URL}register`, {
            name: user.name,
            email: user.email,
            password: user.password
        });
    }

    refreshToken() {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user && user.accessToken) {
            return axios
                .post(`${API_URL}refresh`, {}, {
                    headers: {
                        'Authorization': `${user.tokenType} ${user.accessToken}`
                    }
                })
                .then(response => {
                    if (response.data.accessToken && response.data.accessToken.token) {
                        user.accessToken = response.data.accessToken.token;
                        localStorage.setItem('user', JSON.stringify(user));
                    }

                    return response.data;
                });
        }
        return Promise.reject(new Error("No user or access token found"));
    }
}

export default new AuthService();
